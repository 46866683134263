<template>
  <LogsHistory :id="userId" owner-type="user" :allow-first-time="false" />
</template>

<script>
import LogsHistory from "@/components/Common/LogsHistory";

export default {
  name: "UserLogs",
  components: { LogsHistory },
  computed: {
    userId() {
      const route = this.$route;
      return !!route ? route.params.user : "";
    },
  },
};
</script>
